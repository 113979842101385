import React from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {useQuery } from '@tanstack/react-query' 
import MiddleItemList from '../components/middle/MiddleItemList';
import SeoHelmet from '../components/metaTag/SeoHelmet';
 

export default function MiddleCategoryPage() {

    let {midId} = useParams();
    
    midId = midId ? midId : 1 ;

    let pageNationMinus3 = parseInt(midId) - 3;
    let pageNationMinus2 = parseInt(midId) - 2;
    let pageNationMinus1 = parseInt(midId) - 1;

    let pageNationPlus1 = parseInt(midId) + 1;
    let pageNationPlus2 = parseInt(midId) + 2;
    let pageNationPlus3 = parseInt(midId) + 3;



    let middlePageReviewResult = useQuery(['middleReview'+midId], () => 
        axios.get('https://review.ryokonote.com/api/get-item-review.php?middle_category_code='+midId)
        .then((list) =>{
   
            return list.data
         }),
         {staleTime : 500000}
    )

    

    let middlePageItemResult = useQuery(['middleItem'+midId], () => 
        axios.get('https://review.ryokonote.com/api/get-selling-itemlist.php?middle_category_code='+midId)
        .then((list) =>{
            
            return list.data
         }),
         {staleTime : 500000}
    )


    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;

    


    return (
        <>

            {middlePageReviewResult.data &&(
                    <SeoHelmet
                        title={`${middlePageReviewResult.data[0].title_add_head_explanation_text}${middlePageReviewResult.data[0].middle_category_name}おすすめする理由と特徴、長所と短所について`}
                        description={`${middlePageReviewResult.data[0].item_recommend_reason.split('@@').join('\n')}`}
                        keywords={`${middlePageReviewResult.data[0].middle_category_name}`}
                      
                    />
            )}

            {middlePageReviewResult.data && middlePageItemResult.data && (middlePageItemResult.data[0]) &&(
                    <SeoHelmet
                        title={`${middlePageReviewResult.data[0].title_add_head_explanation_text}${middlePageReviewResult.data[0].middle_category_name}おすすめする理由と特徴、長所と短所について`}
                        description={`${middlePageReviewResult.data[0].item_recommend_reason.split('@@').join('\n')}`}
                        keywords={`${middlePageReviewResult.data[0].middle_category_name}`}
                        imgsrc={`${middlePageItemResult.data[0].Item.mediumImageUrls[0].imageUrl.replace('?_ex=128x128','')}`}
                    />
            )}


            <div>
                {middlePageReviewResult.isLoading && 
                    <>
                 
                  

                    <div className='h-screen flex mx-2'>
                       
                       <div type="button" className=" justify-center m-auto py-2 px-4 flex justify-center items-center  bg-black hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg max-w-md">
                           <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                               <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                               </path>
                           </svg>
                           少々お待ちください
                       </div>

                   </div>
                    </>
                }
                {middlePageReviewResult.error && <div>Loading error</div>}
                {middlePageReviewResult.data && (
                    <>
                        

                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">{middlePageReviewResult.data[0].middle_category_name}</p>

                                <h1 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                                    {middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}おすすめする理由と特徴、長所と短所について
                                </h1>

                                
                                </div>
                            </div>
                    </div>



                    <div>
                        {middlePageReviewResult.data[0].item_recommend_reason &&
                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}のおすすめする理由および特徴は？</h2>

                                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {middlePageReviewResult.data[0].item_recommend_reason.split('@@').join('\n')}
                                </p>

                                {middlePageReviewResult.data[0].item_recommend_target &&(
                                <>
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}は誰におすすめですか？</h2>

                                    <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {middlePageReviewResult.data[0].item_recommend_target.split('@@').join('\n')}
                                </p>
                                </>
                            )}
                            </div>

                            
                        </div>
                        }

                        {middlePageItemResult.data && (middlePageItemResult.data[0]) && (
                            <>
               

                            <div className="bg-white py-3">
                                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">BEST</p>

                                                <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}のおすすめ関連商品</h3>

                                                <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}人気ランキング&順位リスト BEST {middlePageItemResult.data.length}</p>
                                                </div>
                                            </div>
                                    </div>
                            
                            <MiddleItemList reviewData={middlePageReviewResult.data[0]} middleItemData={middlePageItemResult.data}/>
                                </>
                            
                            )
                        }

                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">

                                


                            {middlePageReviewResult.data[0].item_strength &&(
                                <>
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}の長所は何ですか？</h2>

                                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {middlePageReviewResult.data[0].item_strength.split('@@').join('\n')}
                                </p>
                                </>
                            )}


                            {middlePageReviewResult.data[0].item_weakness &&(

                                <>
                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{middlePageReviewResult.data[0].title_add_head_explanation_text}{middlePageReviewResult.data[0].middle_category_name}の短所は何ですか？</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {middlePageReviewResult.data[0].item_weakness.split('@@').join('\n')}
                                </p>
                                </>
                            )}


                            
                                
                            </div>
                        </div>
                    </div>
                    </>

                )}
            </div>

            <nav aria-label="Page navigation example">
                <ul className="inline-flex -space-x-px text-sm">
                  

                { pageNationMinus3>0 &&
                    <li>
                         <Link to={`/middle/${pageNationMinus3}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationMinus3}`}</Link>
                    </li>
                  }


                  { pageNationMinus2>0 &&
                    <li>
                         <Link to={`/middle/${pageNationMinus2}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationMinus2}`}</Link>
                    </li>
                  }

                  { pageNationMinus1>0 &&
                    <li>
                         <Link to={`/middle/${pageNationMinus1}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationMinus1}`}</Link>
                    </li>
                   }


                    <li>
                         <Link to={`/middle/${midId}`} className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{`${midId}`}</Link>
                    </li>




                


                  

                    { pageNationPlus1<10290 &&
                        <li>
                            <Link to={`/middle/${pageNationPlus1}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationPlus1}`}</Link>
                        </li>
                    }

                    { pageNationPlus2<10290 &&
                        <li>
                            <Link to={`/middle/${pageNationPlus2}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationPlus2}`}</Link>
                        </li>
                    }

                    { pageNationPlus3<10290 &&
                        <li>
                            <Link to={`/middle/${pageNationPlus3}`} className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{`${pageNationPlus3}`}</Link>
                        </li>
                    }



                    
         
                   
                </ul>
            </nav>                    

        </>
    );
}

