import React from 'react';
import SeoHelmet from '../components/metaTag/SeoHelmet';

export default function Page404() {
    return (

       
        <div>
             <SeoHelmet
                    title={`404 Page`}
                    description={`404 Page`}
                    keywords={'404 Page'}
                    imgsrc={`https://review.ryokonote.com/image/logo/reviewryoko.png`}
                />
            No Page.
        </div>
    );
}

