import './App.css';
import { Routes, Route } from 'react-router-dom';
import MiddleCategoryPage from './routes/MiddleCategoryPage';
import Page404 from './routes/Page404';
import HeaderNav from './components/HeaderNav';
import SiteHomePage from './routes/SiteHomePage';
import TopCategoryPage from './routes/TopCategoryPage';
import Footer from './components/Footer'
import SeoHelmet from './components/metaTag/SeoHelmet'

function App() {
  return (
    <div className="App">
      <SeoHelmet/>
      <HeaderNav/>
    

      <Routes>
        <Route path='/' element={<SiteHomePage/>}/>
        <Route path='/top' element={<TopCategoryPage/>}/>
        <Route path='/top/:topId' element={<TopCategoryPage/>}/>
        <Route path='/middle' element={<MiddleCategoryPage/>}/>
        <Route path='/middle/:midId' element={<MiddleCategoryPage/>}/>
        <Route path='*' element={<Page404/>}/>
      </Routes>
      
      <Footer/>
      
    </div>
  );
}

export default App;
