import React from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import {useQuery } from '@tanstack/react-query' 
import TopItemList from '../components/top/TopItemList';
import SeoHelmet from '../components/metaTag/SeoHelmet';

export default function TopCategoryPage() {

    let {topId} = useParams();
    
    topId = topId ? topId : 100371 ;


    let topExplanationResult = useQuery(['topExplanationResult'+topId], () => 
        axios.get('https://review.ryokonote.com/api/get-top-category-explanation.php?top_category_code='+topId)
        .then((list) =>{
          
            return list.data
         }),
         {staleTime : 500000}
    )


    let TopPageItemResult = useQuery(['middleItem'+topId], () => 
        axios.get('https://review.ryokonote.com/api/get-selling-using-top-category-code.php?top_category_code='+topId)
        .then((list) =>{
       
            return list.data
         }),
         {staleTime : 500000}
    )
 

    let middleCategoryListResult = useQuery(['middleCategoryList'+topId], () => 
        axios.get('https://review.ryokonote.com/api/get-middle-category-list.php?top_category_search_code='+topId)
        .then((list) =>{
     
            return list.data;
         }),
         {staleTime : 500000}
    )

    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;


    return (
        <>

            <div>

            {topExplanationResult.data &&(
                    <SeoHelmet
                        title={`${topExplanationResult.data[0].top_category_name}選択時に考慮すべき点 、カテゴリ 、おすすめ重要ポイントについて`}
                        description={`${topExplanationResult.data[0].item_what_is_this.split('@@').join('\n')}`}
                        keywords={`${topExplanationResult.data[0].top_category_name}`}
                    />
            )}

            
            {topExplanationResult.data && TopPageItemResult.data &&(
                    <SeoHelmet
                        title={`${topExplanationResult.data[0].top_category_name}選択時に考慮すべき点 、カテゴリ 、おすすめ重要ポイントについて`}
                        description={`${topExplanationResult.data[0].item_what_is_this.split('@@').join('\n')}`}
                        keywords={`${topExplanationResult.data[0].top_category_name}`}
                        imgsrc={`${TopPageItemResult.data[0].Item.mediumImageUrls[0].imageUrl.replace('?_ex=128x128','')}`}
                    />
            )}

                {topExplanationResult.isLoading &&  
                    <>
                    
                    
                    </>
                }
                {topExplanationResult.error &&<div>Loading error</div>}
                {topExplanationResult.data &&(
                    <>
                        
                    
                    


                    <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">{topExplanationResult.data[0].top_category_name}</p>

                                <h1 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">
                                    {topExplanationResult.data[0].top_category_name}選択時に考慮すべき点 、カテゴリ 、おすすめ重要ポイントについて
                                </h1>

                                
                                </div>
                            </div>
                    </div>



                    <div>
                        
                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">
                                <h2 className="mb-4 text-center text-2xl font-bold text-gray-800 sm:text-3xl md:mb-6">{topExplanationResult.data[0].top_category_name}とは何か？</h2>

                                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {topExplanationResult.data[0].item_what_is_this.split('@@').join('\n')}
                                </p>


                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{topExplanationResult.data[0].top_category_name}選択時に考慮すべき点</h2>

                                <p className="mb-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {topExplanationResult.data[0].item_choose_consider.split('@@').join('\n')}
                                </p>
                            </div>
                        </div>



                        {TopPageItemResult.data && (
                            <>
                            
                            


                            <div className="bg-white py-3">
                                            <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                                                <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                                                <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">BEST</p>

                                                <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{topExplanationResult.data[0].top_category_name}のおすすめ関連商品</h3>

                                                <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{topExplanationResult.data[0].top_category_name}人気ランキング&順位リスト BEST {TopPageItemResult.data.length}</p>
                                                </div>
                                            </div>
                                    </div>
                            
                            <TopItemList reviewData={topExplanationResult.data[0]} topItemData={TopPageItemResult.data}/>
                                </>
                            
                            )
                        }

                       

                        <div className="bg-white py-6 sm:py-8 lg:py-12">
                            <div className="mx-auto max-w-screen-md px-4">

                                


                                


                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{topExplanationResult.data[0].top_category_name}のカテゴリーの説明</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                    {topExplanationResult.data[0].item_category_description.split('@@').join('\n')}
                                </p>


                                <h2 className="text-center text-2xl font-bold text-gray-800 sm:text-3xl mt-32 mb-12">{topExplanationResult.data[0].top_category_name}はなぜ重要なのか？</h2>

                                <p className="m-6 text-gray-500 sm:text-lg md:mb-8 whitespace-pre-line text-left">
                                {topExplanationResult.data[0].item_important_reason.split('@@').join('\n')}
                                </p>
                                
                            </div>
                        </div>
                    </div>
                    </>

                )}
            </div>

            {topExplanationResult.data &&(
                <div className="bg-white py-3">
                    <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
                        <div className="rounded-lg bg-gray-100 px-4 py-6 md:py-8 lg:py-12">
                        <p className="mb-2 text-center font-semibold text-indigo-500 md:mb-3 lg:text-lg">Category</p>

                        <h3 className="mb-4 text-center text-2xl font-bold text-gray-800 md:mb-6 lg:text-3xl">{topExplanationResult.data[0].top_category_name}</h3>

                        <p className="mx-auto max-w-screen-md text-center text-gray-500 md:text-lg">{topExplanationResult.data[0].top_category_name}関連のテーマ</p>
                        </div>
                    </div>
                </div>
            )}
                
                {middleCategoryListResult.isLoading && 
                    <div className='h-screen flex mx-2'>
                       
                        <div type="button" className=" justify-center m-auto py-2 px-4 flex justify-center items-center  bg-black hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg max-w-md">
                            <svg width="20" height="20" fill="currentColor" className="mr-2 animate-spin" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z">
                                </path>
                            </svg>
                            少々お待ちください
                        </div>

                    </div>
                }
                {middleCategoryListResult.error && <div>Loading error</div>}
                {middleCategoryListResult.data &&   topExplanationResult.data &&
                    <>
                        <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 px-2 my-10">
                            {middleCategoryListResult.data.map((it,index)=>(
                                //  <div>{it.middle_category_name}</div>

                                <Link to={"/middle/"+it.middle_category_id} key={it.middle_category_id} className="p-2 sm:w-1/2 w-full hover:bg-rose-700 hover:text-white">
                                    <div className="bg-white-100 border-2 rounded flex p-4 h-full items-center">
                                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" className="text-pink-500 w-6 h-6 flex-shrink-0 mr-4" viewBox="0 0 20 20">
                                            <path fill="none" d="M11.611,10.049l-4.76-4.873c-0.303-0.31-0.297-0.804,0.012-1.105c0.309-0.304,0.803-0.293,1.105,0.012l5.306,5.433c0.304,0.31,0.296,0.805-0.012,1.105L7.83,15.928c-0.152,0.148-0.35,0.223-0.547,0.223c-0.203,0-0.406-0.08-0.559-0.236c-0.303-0.309-0.295-0.803,0.012-1.104L11.611,10.049z"></path>
                                        </svg>
                                        <span className="title-font font-medium">{it.middle_category_name}</span>
                                    </div>
                                </Link>

                                
                                
                            ))}
                         </div>
                    </>
                }

        </>
    );
}

